import React, { useEffect, useState } from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

interface AstolfoData {
    uuid: string;
}

function getAstolfo(): Promise<string> {
    return fetch('https://api.astolfo.jp/random', {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        }
    })
        .then(response => response.json() as Promise<AstolfoData>)
        .then(data => `https://api.astolfo.jp/images/${data.uuid}`);
}

async function fetchAstolfo() {
    for (let r = 0; r < 5; r++) {
        const row = document.createElement('div');
        row.className = 'row';
        document.getElementsByClassName("images")[0].appendChild(row);
        for (let c = 0; c < 6; c++) {
            const url = await getAstolfo();
            const img = document.createElement('img');
            img.src = url;
            img.className = 'img-fluid';
            const col = document.createElement('div');
            col.className = 'col';
            col.appendChild(img);
            row.appendChild(col);
        }
    }
}

const App: React.FC = () => {
    useEffect(() => {
        fetchAstolfo();
    }, []);
    return (
        <main className="">
            <div className="images container-fluid">
            </div>
            <div className="container-fluid">
                <div className="row p-5">
                    <div className="offset-5 col">
                        <button className="btn btn-primary align-content-center" onClick={fetchAstolfo}>もっと表示</button>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default App;
